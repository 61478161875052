import React from 'react';
import ReactDOM from 'react-dom/client';

import 'bootstrap/dist/css/bootstrap.css';
// Put any other imports below so that CSS from your
// components takes precedence over default styles.

import './index.css';
import App from './App';

import "./i18n";

// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// import reportWebVitals from './reportWebVitals';

// import "./i18n";

import { BrowserRouter } from 'react-router-dom';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Provider } from 'react-redux';
import store from './Redux';
import { CookiesProvider } from "react-cookie";

import { ThemeUIProvider } from 'theme-ui';

import theme from "./theme";

// const API_KEY = `AIzaSyDHwZWzzJZPqvIHleNmWIPlyDuDzF7uypg`;
const client_id = `685075310296-te21oid6s9j9luu8gu20d9vo1582v8cl.apps.googleusercontent.com`;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <GoogleOAuthProvider clientId={client_id}>
      <CookiesProvider>
        <Provider store={store}>
          <ThemeUIProvider theme={theme}>
            <App />
          </ThemeUIProvider>
        </Provider>
      </CookiesProvider>
    </GoogleOAuthProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
