import React, { Suspense, useEffect, useRef, useState } from 'react';
import './App.css';

import { Route, Routes, } from 'react-router-dom';

import { useThemeColor } from './hooks/useThemeColor';

// custom-sweet-alert
import CustomSweetAlert2 from './Components/Shared/CustomSweetAlert/CustomSweetAlert2';
import SuccessDetails from './Components/Shared/DetailsModals/SuccessDetails/SuccessDetails';

import { AnimatePresence, motion } from "framer-motion";

// layouts

// pages
const Home = React.lazy(() => import('./Pages/Home/LinkedHome'));

const Loader = React.lazy(() => import('./Components/Shared/Loader/Loader'));
const Progressbar = React.lazy(() => import('./Components/Shared/Progressbar/Progressbar'));
const CustomAlert = React.lazy(() => import('./Components/Shared/CustomAlert/CustomAlert'));


// export const rootUrl = process.env.REACT_APP_BASE_URL;
export const localUrl = `http://192.168.0.209:81/`;
export const serverUrl = `https://glo365.app`;
// export const rootUrl = localUrl;
export const rootUrl = serverUrl;
export const userKey = "gsuserkeys";

export const exchangeLaunch_site = "https://9imax.com/";

// export const origin = process.env.REACT_APP_BASE_ORIGIN;
export const RE_DIGIT = new RegExp(/^\d+$/);

function App() {

  const appBG = process.env.PUBLIC_URL + "/changables/appBG.JPEG";
  const { colorMode } = useThemeColor();

  // console.log(process.env.REACT_APP_BASE_URL);

  const [percentage, setPercentage] = useState(((new Date())?.getSeconds()) % 100);
  const [progressBar, setProgressBar] = useState(true);

  useEffect(() => {
    const progressTimer = 500;
    if (
      appBG
    ) {
      if (percentage === 100) {
        setTimeout(() => {
          setProgressBar(false);
        }, progressTimer);
      } else {
        setPercentage(100);
      }
    }
    else {
      setTimeout(() => {
        setPercentage(80);
      }, progressTimer);
    }
  }, [appBG, percentage]);

  const installableBtnRef = useRef();

  useEffect(() => {
    sessionStorage.removeItem("isFirstLoad");
    const ready = (e) => {
      e.preventDefault();
      installableBtnRef.current = e;
    };

    window.addEventListener("beforeinstallprompt", ready);
    return () => {
      window.removeEventListener("beforeinstallprompt", ready);
    };
  }, []);

  let parentUrl = (window.location !== window.parent.location) ? document.referrer : document.location.href;
  let url_val = parentUrl?.split('/');
  let currentRoute = url_val?.[3];

  // console.log(currentRoute, promotionalsBG);

  return (
    <Suspense
      fallback={
        <Loader show={true} />
      }
    >
      <div className={"App row g-0 justify-content-center " + colorMode} style={{ backgroundColor: "", backgroundImage: `url(${appBG})`, }}>
        {
          (
            percentage <= 100 &&
            progressBar
          ) ?
            <Progressbar percentage={percentage} />
            :
            <>
              <div className={(currentRoute === "download_app_promo") ? "" : "centered-main-section"} id="main">
                {/* <Loader /> */}

                <AnimatePresence>
                  <motion.div
                    initial={{ opacity: 0, x: "-50%" }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: "50%" }}
                    transition={{ duration: 0.3, ease: "easeInOut" }}
                  >
                    <Routes>
                      <Route index element={<><Home installableBtnRef={installableBtnRef} /></>} />
                    </Routes>
                  </motion.div>
                </AnimatePresence>
              </div>
              <CustomAlert />
              <CustomSweetAlert2 />
              <SuccessDetails />
            </>
        }

      </div>
    </Suspense>
  );
}

export default App;
